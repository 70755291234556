import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import url from 'url';
var queryParams = url.parse(document.location.href, true).query;
var theme = queryParams.theme || window.localStorage.getItem('mumble.theme');
var themes = {
  'MetroMumbleLight': 'MetroMumbleLight',
  'MetroMumbleDark': 'MetroMumbleDark',
  'light': 'MetroMumbleLight',
  'dark': 'MetroMumbleDark',
  'Raddinox': 'Raddinox',
  'raddinox': 'Raddinox'
};
theme = themes[theme] || window.mumbleWebConfig.defaults.theme;
window.theme = theme;

var _MetroMumbleLight$Met = _slicedToArray({
  'MetroMumbleLight': [require('../themes/MetroMumbleLight/loading.scss'), require('../themes/MetroMumbleLight/main.scss')],
  'MetroMumbleDark': [require('../themes/MetroMumbleDark/loading.scss'), require('../themes/MetroMumbleDark/main.scss')],
  'Raddinox': [require('../themes/Raddinox/loading.scss'), require('../themes/Raddinox/main.scss')]
}[theme], 2),
    loadingTheme = _MetroMumbleLight$Met[0],
    mainTheme = _MetroMumbleLight$Met[1];

function useStyle(url) {
  var style = document.createElement('link');
  style.rel = 'stylesheet';
  style.type = 'text/css';
  style.href = url;
  document.getElementsByTagName('head')[0].appendChild(style);
}

useStyle(loadingTheme);
useStyle(mainTheme);